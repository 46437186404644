@import "../Assets/font.css";
header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
    height: 60px;
	z-index: 1000;
	transition: 0.6s ease-in-out;
}
header.sticky{
	transition: 0.6s ease-in-out;
	padding: 5px 100px;
	border-bottom: 1px solid rgba(0,0,0,.1); 
}
#logo{
    font-size: 1.2vw;
    font-family: 'Inter';
	transition: 0.6s ease-in-out;
    font-weight: bold;
    font-family: 'Ogg';
}
.toggle-menu{
    display: none;
}
.menu-items{
    position: fixed;
    top: 50px;
    right: 60px;
    list-style: none;
    text-align: right;
	transition: 0.6s ease-in-out;
    transition: display 1s ease-in-out;
}
ul.sticky{
    display: flex;
    top: 20px;
    right: 30px;
	transition: 0.6s;
}
.menu-items li,  .li{
    font-family: 'Inter';
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 20px;
    overflow: hidden;
    color: black;
    text-decoration: none;
    cursor: pointer;
    margin-left: 20px;
    mix-blend-mode: difference;
}
.li:hover::before {
	opacity: 1;
	transform: scaleX(1);
	transform-origin: 0 50%;
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear;
}
.li::before {
	content: "";
    position: absolute;
    left: auto;
    margin-top: 22px;
    width: 40%;
    /* padding-right: 60px; */
    height: 2px;
    overflow: hidden;
    background: #642420;
    transform: scaleX(0.001);
    opacity: 0;
    transform-origin: 100% 50%;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s;
}
@media (max-width:1200px) {
    .menu-items li,  .li{
        font-size: 20px;
    }
    .li::before{
        margin-top: 23px;
    }
}
@media (max-width:768px) {
    .menu-items{
        display: none;
        background-color: #161616;
        top: 80px;
        right: 20px;
        padding: 10px 0px;
    }
    .menu-items li,  .li{
        color: #f0f0f0;
        font-size: 15px;
    padding-right: 10px;
    padding-bottom: 2px;
    }
    .li::before{
        background: #f0f0f0;
    }
    .toggle-menu{
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
        margin: 10px 10px;
        padding: 0px 10px;
        padding-top: 5px;
        border-radius: 1px;
        background-color: #161616;
    }
    .toggle-menu img{
        width: 40px;
        font-weight: lighter;
        color: #f0f0f0;
    }
    header:hover .menu-items{
        display: block;
    }
}
@media (max-width:400px){
    .menu-items{
        top: 60px;
        right: 10px;
    }
    .toggle-menu{
        right: 10px;
        top: 15px;
        padding: 0px 8px;
        padding-top: 4px;
    }
    .toggle-menu img{
        width: 25px;
    }
}