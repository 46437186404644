@import './Assets/font.css';
html,
body{
  background-color: #e9dbd4;
  /* background-color: #f3e0d3; */
  /* background-color: #f0f0f0; */
  /* overflow-x: hidden; */
  height: 0;
}
.app{
  margin: 0;
  padding: 0;
}
.noise::before{
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    opacity: .03;
    z-index: 1500;
    pointer-events: none;
    background: url(Assets/noise.gif);
}
.cursor-follower{
  position: absolute;
  background: url(Assets/Red_asterisk.svg) no-repeat 50% 50%;
  background-size: cover;
  width: 70px;
  height: 70px;
  animation: animate-rotate 10s infinite linear;
  z-index: 1;
  user-select: none;
  opacity: .8;
  overflow: hidden;
  pointer-events: none;
  transform: translate(35px,35px);
}
@keyframes animate-rotate {
  to{
    transform: rotate(0deg);
  }
  from{
    transform: rotate(360deg);
  }
}
@media (max-width:768px) {
  .cursor-follower{
    display: none;
  }
}
::-webkit-scrollbar{
  display: none;
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  background-color: #e9dbd4;
}
.progress{
  position: absolute;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  flex-direction: column;
}
#percent{
  position: absolute;
  top: 10%;
  left: 10%;
  margin-bottom: 50px;
  color: black;
  font-size: 100px;
  font-family: 'Ogg';
}
#bar{
  width: 80%;
  margin-top: 20px;
}
#barc{
  width: 1%;
  padding: 1px 0px;
  background-color: black;

}