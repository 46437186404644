@import '../Assets/font.css';
.container-text{
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10%;
    margin-top: 10vh;
    display: flex;
    font-size: 200px;
    font-family: 'Ogg';
    font-weight: lighter;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    letter-spacing: -10px;
}
.container-text p{
    overflow: hidden;
}
.container-inner-text2{
    margin-top: -120px;
}
.container-qoute{
    position: absolute;
    top: 70vh;
    right: 100px;
    font-family: 'Inter';
    font-size: 15px;
    color: #665353;
    word-spacing: 10px;
    font-weight: lighter;
}
.container-image{
    position: absolute;
    top: 95vh;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50vw;
    background: url(../Assets/image.jpeg) 50% 50% no-repeat;
    background-size: cover;
    filter: grayscale(1);
    height: 110vh;
    z-index: -1;
}
.container-qoute2{
    position: absolute;
    top: 160vh;
    left: 10%;
}
.about-container2{
    position: absolute;
    top: 190vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250vh;
    width: 100%;
    flex-direction: column;
}
.about-container2-heading,
.about-container2-heading2,
.about-container2-heading2 p {
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 50px;
    
}
.about-container2-heading p,
.about-container2-heading2 p{
    font-size: 200px;
    font-family: 'Ogg';
    line-height: 80%;
    font-weight: lighter;
    letter-spacing: -8px;
    /* line-height: -100px; */
}
.about-container2-heading2 p{
    text-decoration: line-through 5px #806868;
    line-height: 80%;
}
.about-container2-p p{
    font-size: 18px;
    font-family: 'Inter';
    margin-bottom: 80px;
    color: #665353;
    word-spacing: 12px;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-container2-p-right{
    text-align: right;
}
.skill-set-about-p{
    position: absolute;
    top: 170vh;
    right: 100px;
}
.skill-set-about-p p{
    
    font-size: 15px;
    font-family: 'Inter';
    margin-bottom: 50px;
    text-align: right;
    word-spacing: 10px;
    margin-right: 50px;
    margin-left: 50px;
    color: #665353;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-container-flower{
    position: absolute;
    top: 80vh;
    left: 100px;
    animation: animate-rotate 10s infinite linear;
}
.about-container-flower img{
    
    width: 100px;
}
@keyframes animate-rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.about-header{
    position: absolute;
    top: 500vh;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    height: 200vh;
    background: url(../Assets/about.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.about-header-text{
    margin-top: 150vh;
    margin-left: 50%;
    margin-right: 50px;

}
.about-header-text p{
    
    font-size: 25px;
    font-family: 'Inter';
    margin-bottom: 50px;
    text-align: right;
    word-spacing: 10px;
    margin-right: 50px;
    margin-left: 50px;
    color: #ffffff;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-footer{
    margin-top: 720vh;
}
.about-container2-p{
    margin-left: 20px;
    margin-right: 20px;
}
@media (max-width:1200px) {
    .about-header-text{
        margin-top: 100vh;
        margin-left: 30%;
    }
    .container-qoute{
        right: 20px;
    }
}

@media (max-width:1000px) {
    .container-text{
        font-size: 120px;
    }
    .container-inner-text2{
        margin-top: -80px;
    }
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 140px;
    }
    .about-container-flower{
        top: 40vh;
    }
    .skill-set-about-p{
        right: 10px;
    }
    
.about-container2-heading{
    margin-top: 100px;
}
.container-image{
    top: 85vh;
    width: 70vw;
    height: 110vh;
}
}
@media (max-width:650px){
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 100px;
    }
    .container-qoute1{
        top: 50vh;
        mix-blend-mode: difference;
    }
    .container-image{
        /* top: 85vh; */
        width: 95vw;
        /* height: 110vh; */
    }
    .about-header-text p{
        font-size: 15px;
    }
    .container-text{
        margin-left: 10px;

    }
    .about-container2-p p{
        font-size: 15px;
    }
    .about-header{
        top: 450vh;
    }
    .about-header-text{
        margin-right: 10px;
        margin-left: 30px;
    }
    .about-footer{
        margin-top: 680vh;
    }
}
@media (max-width:400px){
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 80px;
        letter-spacing: -5px;
    }
    .container-text{
        font-size: 80px;
        letter-spacing: -5px;
    }
    .container-inner-text2{
        margin-top: -50px;
    }
    .container-image{
        top: 80vh;
        width: 97vw;
        /* height: 110vh; */
    }
    .about-header{
        top: 540vh;
    }
    .about-footer{
        margin-top: 780vh;
    }
}