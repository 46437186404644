@import '../Assets/font.css';
.get-in-touch{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 250px;
    flex-direction: column;
    height: 600px;
}
.get-in-touch h1{
    font-size: 200px;
    font-weight: lighter;
    font-family: 'Ogg';
}
.get-in-touch p{
    font-size: 20px;
    font-weight: lighter;
    font-family: 'Inter';
}
.get-in-touch img{
    width: 500px;
    margin-top: -400px;
    opacity: .1;
    animation: animate-rotate 30s linear infinite;
}
@keyframes animate-rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@media (max-width: 1300px){
    .get-in-touch h1{
        
    font-size: 100px;
    }
}
@media (max-width: 700px){
    .get-in-touch h1{
        
    font-size: 60px;
    }
    .get-in-touch img{
        width: 200px;
        margin-top: -150px;
        opacity: .1;
        animation: animate-rotate 30s linear infinite;
    }

    .get-in-touch p{
        font-size: 10px;
        font-weight: lighter;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        font-family: 'Inter';
    }
}
@media (max-width: 500px){
    .get-in-touch h1{
        
        font-size: 40px;
        }
        .get-in-touch p{
            font-size: 10px;
            font-weight: lighter;
            font-family: 'Inter';
        }
        .get-in-touch{
            height: 400px;
        }
}