*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  position: absolute;
  right: 5px;
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: #745857;
  border-radius: 10px;
  margin-right: 5px;

}
::-moz-selection { 
  color: #e0e0e0;
  background: #161616;
}

::selection {  
  color: #e0e0e0;
  background: #161616;

}