@import "../Assets/font.css";
*{
    font-family: 'Ogg';
}
.container{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    height: 50vh;
    width: 100%;
}
.container1{
    display: flex;
    flex-direction: column;
    padding: 0px 100px;
    height: 100%;
    justify-content: center;
    overflow: hidden;
}
.txt-line{
    overflow: hidden;
}
.txt-line p{
    font-size: 200px;
    text-transform:capitalize;
    letter-spacing: -10px;
    font-family: 'Ogg';
    overflow: hidden;
}
.line-bottom{
    margin-top: -130px;
}
.right-side-menu{
    position: absolute;
    top: 40vh;
    text-align: right;
    right: 60px;
}
.right-side-menu p{
    
    font-size: 20px;
    font-family: 'Inter';
    color: #665353;
    cursor: pointer;
    font-weight: lighter;  
}
.right-side-menu p:hover::before {
	opacity: 1;
	transform: scaleX(1);
	transform-origin: 0 50%;
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear;
}
.right-side-menu p::before {
	content: "";
    position: absolute;
    left: auto;
    margin-top: 23px;
    width: 50%;
    /* padding-right: 60px; */
    height: 2px;
    overflow: hidden;
    background: #642420;
    transform: scaleX(0.001);
    opacity: 0;
    transform-origin: 100% 50%;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s;
}

.left-side-quote{
    margin-left: 120px;
    
}
.left-side-quote p{
    color: black;
    font-size: 15px;
    font-family: 'Inter';
    word-spacing: 10px;
    text-transform: uppercase;
    font-weight: lighter;
}

.flower-svg{
    margin-left: 150px;
    margin-top: -250px;
}
.flower-svg img{
    
    animation: animate-rotate 30s infinite linear;
    width: 80px;
}
.short-about{
    margin-top: 25vh;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}
.main-h1-short-about{
    margin-top: 100px;
}
.sub-main-p-short-about{
    margin-top: 120px;
}
.short-about h1{
    font-size: 150px;
    font-weight: 500;
    font-family: 'Ogg';
    margin-bottom: -100px;
    letter-spacing: -2px;
    text-align: center;
}
.short-about p{
    text-align: center;
    color: #665353;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
}
.another-svg{
    margin-top: 50px;
}
.short-about .another-svg img{
    width: 50px;
    animation: animate-rotate 10s infinite linear;
}
@keyframes animate-rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.my-skills-main-reel{
    width: 100%;
    overflow: hidden;
}
.my-skills-reel{
    margin-top: 300px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: fit-content;
    will-change: transform;
    animation: animation-reel 60s infinite linear;
}
@keyframes animation-reel{
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.reel-item{
    font-weight: 400;
    font-family: 'Ogg';
    font-size: 180px;
    text-transform: uppercase;
}
.skill-set-boxes{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 100px;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
}
.skill-set-box{
    margin: 50px 10px;
    display: flex;
    width: 350px;
    height: 200px;
    color: #e6e6e6;
    flex-direction: column;
    background-color: #101010;
    border: 1px solid transparent;
    transition: color .8s cubic-bezier(.16,1,.3,1);
    transition: border .8s cubic-bezier(.16,1,.3,1);
    transition: background-color 1s cubic-bezier(.16,1,.3,1);
}
.skill-set-box:hover{
    color: #161616;
    background-color: #f0f0f0;
    border: 1px solid #161616;
}

.skill-set-box h1,
.skill-set-box p{
    margin: 0px 30px;
    font-family: 'Inter';
    font-weight: lighter;  
    text-transform: uppercase;
}
.skill-set-box h1{
    margin-top: 35px;
    font-size: 20px;
    font-weight: bold;
    
}
.skill-set-box p{
    margin-top: 20px;
    font-size: 13px;
}
.project-and-work{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    flex-direction: column;
    margin-top: 100px;
    height: 30vh;
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px dashed black;
}
.project-and-work h1 .h1-project{
    text-decoration: none;
    color: black;
    font-size: 100px;
    font-weight: lighter;
    font-family: 'ogg';
    text-decoration: .5px line-through;
}
.project-and-work p{
    color: black;
    font-size: 20px;
    margin-top: -20px;
    font-weight: lighter;
    font-family: 'Inter';
}
.h1-project img{
    width: 90px;
}

@media (max-width:1200px) {
    .txt-line p{
        font-size: 100px;
        letter-spacing: -5px;
        font-weight: 500;
    }
    .line-bottom{
        margin-top: -40px;
    }
    .flower-svg{
        display: none;
    }
    .short-about h1{
        font-size: 100px;
        margin: 0;
        line-height: 88%;
    }
    .short-about p{
        
    margin-top: 10px;
    }
    
.sub-main-p-short-about{
    margin-top: 20px;
}
.reel-item{
    font-size: 100px;
}
.project-and-work h1 .h1-project{
    font-size: 50px;
}
.project-and-work h1 .h1-project img{
    width: 30px;
}
.project-and-work p{
    margin-top: -30px;
}
.skill-set-box{
    margin: 10px 5px;
}
}
@media (max-width:600px) {
    .short-about p{
        
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
        }
        .txt-line p{
            font-size: 60px;
            letter-spacing: -2px;
            font-weight: 500;
        }
        .line-bottom{
            margin-top: -35px;
        }  
        .left-side-quote{
            margin-left: 10vw;
        }
        .left-side-quote p{
            font-size: 12px;
        }
        .container1{
            padding: 0px 50px;
        }
        .short-about h1{
            font-size: 60px;
            margin: 0;
            line-height: 88%;
        }
        .reel-item{
            font-size: 60px;
        }
}