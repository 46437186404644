@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  position: absolute;
  right: 5px;
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: #745857;
  border-radius: 10px;
  margin-right: 5px;

}

::selection {  
  color: #e0e0e0;
  background: #161616;

}
@font-face {font-family: "Ogg"; src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot"); src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff") format("woff"), url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.svg#Ogg") format("svg"); }

html,
body{
  background-color: #e9dbd4;
  /* background-color: #f3e0d3; */
  /* background-color: #f0f0f0; */
  /* overflow-x: hidden; */
  height: 0;
}
.app{
  margin: 0;
  padding: 0;
}
.noise::before{
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    opacity: .03;
    z-index: 1500;
    pointer-events: none;
    background: url(/static/media/noise.93fe26ae.gif);
}
.cursor-follower{
  position: absolute;
  background: url(/static/media/Red_asterisk.68f436c5.svg) no-repeat 50% 50%;
  background-size: cover;
  width: 70px;
  height: 70px;
  -webkit-animation: animate-rotate 10s infinite linear;
          animation: animate-rotate 10s infinite linear;
  z-index: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: .8;
  overflow: hidden;
  pointer-events: none;
  -webkit-transform: translate(35px,35px);
          transform: translate(35px,35px);
}
@-webkit-keyframes animate-rotate {
  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animate-rotate {
  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@media (max-width:768px) {
  .cursor-follower{
    display: none;
  }
}
::-webkit-scrollbar{
  display: none;
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  background-color: #e9dbd4;
}
.progress{
  position: absolute;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  flex-direction: column;
}
#percent{
  position: absolute;
  top: 10%;
  left: 10%;
  margin-bottom: 50px;
  color: black;
  font-size: 100px;
  font-family: 'Ogg';
}
#bar{
  width: 80%;
  margin-top: 20px;
}
#barc{
  width: 1%;
  padding: 1px 0px;
  background-color: black;

}
.get-in-touch{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 250px;
    flex-direction: column;
    height: 600px;
}
.get-in-touch h1{
    font-size: 200px;
    font-weight: lighter;
    font-family: 'Ogg';
}
.get-in-touch p{
    font-size: 20px;
    font-weight: lighter;
    font-family: 'Inter';
}
.get-in-touch img{
    width: 500px;
    margin-top: -400px;
    opacity: .1;
    -webkit-animation: animate-rotate 30s linear infinite;
            animation: animate-rotate 30s linear infinite;
}
@-webkit-keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media (max-width: 1300px){
    .get-in-touch h1{
        
    font-size: 100px;
    }
}
@media (max-width: 700px){
    .get-in-touch h1{
        
    font-size: 60px;
    }
    .get-in-touch img{
        width: 200px;
        margin-top: -150px;
        opacity: .1;
        -webkit-animation: animate-rotate 30s linear infinite;
                animation: animate-rotate 30s linear infinite;
    }

    .get-in-touch p{
        font-size: 10px;
        font-weight: lighter;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        font-family: 'Inter';
    }
}
@media (max-width: 500px){
    .get-in-touch h1{
        
        font-size: 40px;
        }
        .get-in-touch p{
            font-size: 10px;
            font-weight: lighter;
            font-family: 'Inter';
        }
        .get-in-touch{
            height: 400px;
        }
}
.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin-top: 150px;
    height: 500px;
    background: #161616;
    text-transform: uppercase;
    color: white;
}
.footer-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 80%;
}
.footer-container .message-for-users span{
   font-size: 14px;
   font-family: 'Inter';
   font-weight: lighter;
   margin: 20px;
}
.footer-container .social-links{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
}
.footer-container .social-links .social-links-h{
font-size: 20px;

margin-bottom: 10px;
font-family: 'Inter';
font-weight: bold;
}
.footer-container .social-links .social-links-items{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.footer a,.footer p{
    color: #e0e0e0;
    text-decoration: none;
    font-family: 'Inter';
    font-size: 15px;
    font-weight: lighter;
    cursor: pointer;
    margin-top: 5px;
    border-bottom: 1px dashed transparent;
    transition: border .8s cubic-bezier(.16,1,.3,1);
    
}
.footer-container p:hover,.footer-container a:hover{
    border-bottom: 1px dashed white;
  transition: -webkit-text-decoration .8s cubic-bezier(.16,1,.3,1);
  transition: text-decoration .8s cubic-bezier(.16,1,.3,1);
  transition: text-decoration .8s cubic-bezier(.16,1,.3,1), -webkit-text-decoration .8s cubic-bezier(.16,1,.3,1);
    
}
.footer-btn{
    padding: 85px 50px;
    border: 1px dashed white;
    border-radius: 50%;

    margin: 20px;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.left-footer-bottom{
    margin-left: 80px;
}

.right-footer-bottom{
    margin-right: 50px;
}

.footer-ticker{
    width: 100%;
    overflow: hidden;
    background-color: white;
}
.footer-ticker-{
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    will-change: transform;
    -webkit-animation: animation-reel 60s infinite linear;
            animation: animation-reel 60s infinite linear;
}
@-webkit-keyframes animation-reel{
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
@keyframes animation-reel{
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
.footer-ticker-item{
    font-weight: bold;
    color: black;
    font-family: 'Inter';
    font-size: 15px;
    text-transform: uppercase;
}
@media (max-width: 900px){
    .footer{
        height: 750px;
    }
    .footer-container{
        flex-wrap: wrap;
        height: 100%;
        
    }
    .message-for-users span{
        display: none;
    }
    .footer-bottom{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin: 5px 0px; */
    }
    .left-footer-bottom{
        margin-left: 0px;
    }
    
    .right-footer-bottom{
        margin-right: 0px;
    }
}
@media (max-width: 400px){
    .footer{
        height: 850px;
    }
    .left-footer-bottom p{
        margin-left: 0px;
        font-size: 12px;
    }
    
    .right-footer-bottom p{
        margin-right: 0px;
        font-size: 12px;
    }
}
*{
    font-family: 'Ogg';
}
.container{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    height: 50vh;
    width: 100%;
}
.container1{
    display: flex;
    flex-direction: column;
    padding: 0px 100px;
    height: 100%;
    justify-content: center;
    overflow: hidden;
}
.txt-line{
    overflow: hidden;
}
.txt-line p{
    font-size: 200px;
    text-transform:capitalize;
    letter-spacing: -10px;
    font-family: 'Ogg';
    overflow: hidden;
}
.line-bottom{
    margin-top: -130px;
}
.right-side-menu{
    position: absolute;
    top: 40vh;
    text-align: right;
    right: 60px;
}
.right-side-menu p{
    
    font-size: 20px;
    font-family: 'Inter';
    color: #665353;
    cursor: pointer;
    font-weight: lighter;  
}
.right-side-menu p:hover::before {
	opacity: 1;
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
	-webkit-transform-origin: 0 50%;
	        transform-origin: 0 50%;
	transition: opacity 0.1s linear, -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.3, 1);
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear;
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear, -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.3, 1);
}
.right-side-menu p::before {
	content: "";
    position: absolute;
    left: auto;
    margin-top: 23px;
    width: 50%;
    /* padding-right: 60px; */
    height: 2px;
    overflow: hidden;
    background: #642420;
    -webkit-transform: scaleX(0.001);
            transform: scaleX(0.001);
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    transition: opacity 0.1s linear 0.2s, -webkit-transform 0.3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s, -webkit-transform 0.3s cubic-bezier(0.2, 1, 0.3, 1);
}

.left-side-quote{
    margin-left: 120px;
    
}
.left-side-quote p{
    color: black;
    font-size: 15px;
    font-family: 'Inter';
    word-spacing: 10px;
    text-transform: uppercase;
    font-weight: lighter;
}

.flower-svg{
    margin-left: 150px;
    margin-top: -250px;
}
.flower-svg img{
    
    -webkit-animation: animate-rotate 30s infinite linear;
    
            animation: animate-rotate 30s infinite linear;
    width: 80px;
}
.short-about{
    margin-top: 25vh;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}
.main-h1-short-about{
    margin-top: 100px;
}
.sub-main-p-short-about{
    margin-top: 120px;
}
.short-about h1{
    font-size: 150px;
    font-weight: 500;
    font-family: 'Ogg';
    margin-bottom: -100px;
    letter-spacing: -2px;
    text-align: center;
}
.short-about p{
    text-align: center;
    color: #665353;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
}
.another-svg{
    margin-top: 50px;
}
.short-about .another-svg img{
    width: 50px;
    -webkit-animation: animate-rotate 10s infinite linear;
            animation: animate-rotate 10s infinite linear;
}
@-webkit-keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.my-skills-main-reel{
    width: 100%;
    overflow: hidden;
}
.my-skills-reel{
    margin-top: 300px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    will-change: transform;
    -webkit-animation: animation-reel 60s infinite linear;
            animation: animation-reel 60s infinite linear;
}
@-webkit-keyframes animation-reel{
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
@keyframes animation-reel{
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
.reel-item{
    font-weight: 400;
    font-family: 'Ogg';
    font-size: 180px;
    text-transform: uppercase;
}
.skill-set-boxes{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 100px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap;
}
.skill-set-box{
    margin: 50px 10px;
    display: flex;
    width: 350px;
    height: 200px;
    color: #e6e6e6;
    flex-direction: column;
    background-color: #101010;
    border: 1px solid transparent;
    transition: color .8s cubic-bezier(.16,1,.3,1);
    transition: border .8s cubic-bezier(.16,1,.3,1);
    transition: background-color 1s cubic-bezier(.16,1,.3,1);
}
.skill-set-box:hover{
    color: #161616;
    background-color: #f0f0f0;
    border: 1px solid #161616;
}

.skill-set-box h1,
.skill-set-box p{
    margin: 0px 30px;
    font-family: 'Inter';
    font-weight: lighter;  
    text-transform: uppercase;
}
.skill-set-box h1{
    margin-top: 35px;
    font-size: 20px;
    font-weight: bold;
    
}
.skill-set-box p{
    margin-top: 20px;
    font-size: 13px;
}
.project-and-work{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    flex-direction: column;
    margin-top: 100px;
    height: 30vh;
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px dashed black;
}
.project-and-work h1 .h1-project{
    text-decoration: none;
    color: black;
    font-size: 100px;
    font-weight: lighter;
    font-family: 'ogg';
    -webkit-text-decoration: .5px line-through;
            text-decoration: .5px line-through;
}
.project-and-work p{
    color: black;
    font-size: 20px;
    margin-top: -20px;
    font-weight: lighter;
    font-family: 'Inter';
}
.h1-project img{
    width: 90px;
}

@media (max-width:1200px) {
    .txt-line p{
        font-size: 100px;
        letter-spacing: -5px;
        font-weight: 500;
    }
    .line-bottom{
        margin-top: -40px;
    }
    .flower-svg{
        display: none;
    }
    .short-about h1{
        font-size: 100px;
        margin: 0;
        line-height: 88%;
    }
    .short-about p{
        
    margin-top: 10px;
    }
    
.sub-main-p-short-about{
    margin-top: 20px;
}
.reel-item{
    font-size: 100px;
}
.project-and-work h1 .h1-project{
    font-size: 50px;
}
.project-and-work h1 .h1-project img{
    width: 30px;
}
.project-and-work p{
    margin-top: -30px;
}
.skill-set-box{
    margin: 10px 5px;
}
}
@media (max-width:600px) {
    .short-about p{
        
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
        }
        .txt-line p{
            font-size: 60px;
            letter-spacing: -2px;
            font-weight: 500;
        }
        .line-bottom{
            margin-top: -35px;
        }  
        .left-side-quote{
            margin-left: 10vw;
        }
        .left-side-quote p{
            font-size: 12px;
        }
        .container1{
            padding: 0px 50px;
        }
        .short-about h1{
            font-size: 60px;
            margin: 0;
            line-height: 88%;
        }
        .reel-item{
            font-size: 60px;
        }
}
header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
    height: 60px;
	z-index: 1000;
	transition: 0.6s ease-in-out;
}
header.sticky{
	transition: 0.6s ease-in-out;
	padding: 5px 100px;
	border-bottom: 1px solid rgba(0,0,0,.1); 
}
#logo{
    font-size: 1.2vw;
    font-family: 'Inter';
	transition: 0.6s ease-in-out;
    font-weight: bold;
    font-family: 'Ogg';
}
.toggle-menu{
    display: none;
}
.menu-items{
    position: fixed;
    top: 50px;
    right: 60px;
    list-style: none;
    text-align: right;
	transition: 0.6s ease-in-out;
    transition: display 1s ease-in-out;
}
ul.sticky{
    display: flex;
    top: 20px;
    right: 30px;
	transition: 0.6s;
}
.menu-items li,  .li{
    font-family: 'Inter';
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 20px;
    overflow: hidden;
    color: black;
    text-decoration: none;
    cursor: pointer;
    margin-left: 20px;
    mix-blend-mode: difference;
}
.li:hover::before {
	opacity: 1;
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
	-webkit-transform-origin: 0 50%;
	        transform-origin: 0 50%;
	transition: opacity 0.1s linear, -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.3, 1);
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear;
	transition: transform 0.7s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear, -webkit-transform 0.7s cubic-bezier(0.2, 1, 0.3, 1);
}
.li::before {
	content: "";
    position: absolute;
    left: auto;
    margin-top: 22px;
    width: 40%;
    /* padding-right: 60px; */
    height: 2px;
    overflow: hidden;
    background: #642420;
    -webkit-transform: scaleX(0.001);
            transform: scaleX(0.001);
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    transition: opacity 0.1s linear 0.2s, -webkit-transform 0.3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.1s linear 0.2s, -webkit-transform 0.3s cubic-bezier(0.2, 1, 0.3, 1);
}
@media (max-width:1200px) {
    .menu-items li,  .li{
        font-size: 20px;
    }
    .li::before{
        margin-top: 23px;
    }
}
@media (max-width:768px) {
    .menu-items{
        display: none;
        background-color: #161616;
        top: 80px;
        right: 20px;
        padding: 10px 0px;
    }
    .menu-items li,  .li{
        color: #f0f0f0;
        font-size: 15px;
    padding-right: 10px;
    padding-bottom: 2px;
    }
    .li::before{
        background: #f0f0f0;
    }
    .toggle-menu{
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
        margin: 10px 10px;
        padding: 0px 10px;
        padding-top: 5px;
        border-radius: 1px;
        background-color: #161616;
    }
    .toggle-menu img{
        width: 40px;
        font-weight: lighter;
        color: #f0f0f0;
    }
    header:hover .menu-items{
        display: block;
    }
}
@media (max-width:400px){
    .menu-items{
        top: 60px;
        right: 10px;
    }
    .toggle-menu{
        right: 10px;
        top: 15px;
        padding: 0px 8px;
        padding-top: 4px;
    }
    .toggle-menu img{
        width: 25px;
    }
}
.container-text{
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10%;
    margin-top: 10vh;
    display: flex;
    font-size: 200px;
    font-family: 'Ogg';
    font-weight: lighter;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    letter-spacing: -10px;
}
.container-text p{
    overflow: hidden;
}
.container-inner-text2{
    margin-top: -120px;
}
.container-qoute{
    position: absolute;
    top: 70vh;
    right: 100px;
    font-family: 'Inter';
    font-size: 15px;
    color: #665353;
    word-spacing: 10px;
    font-weight: lighter;
}
.container-image{
    position: absolute;
    top: 95vh;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 50vw;
    background: url(/static/media/image.42a5af1e.jpeg) 50% 50% no-repeat;
    background-size: cover;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    height: 110vh;
    z-index: -1;
}
.container-qoute2{
    position: absolute;
    top: 160vh;
    left: 10%;
}
.about-container2{
    position: absolute;
    top: 190vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250vh;
    width: 100%;
    flex-direction: column;
}
.about-container2-heading,
.about-container2-heading2,
.about-container2-heading2 p {
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 50px;
    
}
.about-container2-heading p,
.about-container2-heading2 p{
    font-size: 200px;
    font-family: 'Ogg';
    line-height: 80%;
    font-weight: lighter;
    letter-spacing: -8px;
    /* line-height: -100px; */
}
.about-container2-heading2 p{
    -webkit-text-decoration: line-through 5px #806868;
            text-decoration: line-through 5px #806868;
    line-height: 80%;
}
.about-container2-p p{
    font-size: 18px;
    font-family: 'Inter';
    margin-bottom: 80px;
    color: #665353;
    word-spacing: 12px;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-container2-p-right{
    text-align: right;
}
.skill-set-about-p{
    position: absolute;
    top: 170vh;
    right: 100px;
}
.skill-set-about-p p{
    
    font-size: 15px;
    font-family: 'Inter';
    margin-bottom: 50px;
    text-align: right;
    word-spacing: 10px;
    margin-right: 50px;
    margin-left: 50px;
    color: #665353;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-container-flower{
    position: absolute;
    top: 80vh;
    left: 100px;
    -webkit-animation: animate-rotate 10s infinite linear;
            animation: animate-rotate 10s infinite linear;
}
.about-container-flower img{
    
    width: 100px;
}
@-webkit-keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes animate-rotate {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.about-header{
    position: absolute;
    top: 500vh;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 200vh;
    background: url(/static/media/about.62ae9e7f.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.about-header-text{
    margin-top: 150vh;
    margin-left: 50%;
    margin-right: 50px;

}
.about-header-text p{
    
    font-size: 25px;
    font-family: 'Inter';
    margin-bottom: 50px;
    text-align: right;
    word-spacing: 10px;
    margin-right: 50px;
    margin-left: 50px;
    color: #ffffff;
    font-weight: lighter;
    text-transform: uppercase;
}
.about-footer{
    margin-top: 720vh;
}
.about-container2-p{
    margin-left: 20px;
    margin-right: 20px;
}
@media (max-width:1200px) {
    .about-header-text{
        margin-top: 100vh;
        margin-left: 30%;
    }
    .container-qoute{
        right: 20px;
    }
}

@media (max-width:1000px) {
    .container-text{
        font-size: 120px;
    }
    .container-inner-text2{
        margin-top: -80px;
    }
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 140px;
    }
    .about-container-flower{
        top: 40vh;
    }
    .skill-set-about-p{
        right: 10px;
    }
    
.about-container2-heading{
    margin-top: 100px;
}
.container-image{
    top: 85vh;
    width: 70vw;
    height: 110vh;
}
}
@media (max-width:650px){
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 100px;
    }
    .container-qoute1{
        top: 50vh;
        mix-blend-mode: difference;
    }
    .container-image{
        /* top: 85vh; */
        width: 95vw;
        /* height: 110vh; */
    }
    .about-header-text p{
        font-size: 15px;
    }
    .container-text{
        margin-left: 10px;

    }
    .about-container2-p p{
        font-size: 15px;
    }
    .about-header{
        top: 450vh;
    }
    .about-header-text{
        margin-right: 10px;
        margin-left: 30px;
    }
    .about-footer{
        margin-top: 680vh;
    }
}
@media (max-width:400px){
    .about-container2-heading p, .about-container2-heading2 p{
        font-size: 80px;
        letter-spacing: -5px;
    }
    .container-text{
        font-size: 80px;
        letter-spacing: -5px;
    }
    .container-inner-text2{
        margin-top: -50px;
    }
    .container-image{
        top: 80vh;
        width: 97vw;
        /* height: 110vh; */
    }
    .about-header{
        top: 540vh;
    }
    .about-footer{
        margin-top: 780vh;
    }
}
.blog-message{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 90%;
    width: 100%;
    -webkit-text-decoration: line-through 5px #806868;
            text-decoration: line-through 5px #806868;
    margin-bottom: 150px;
    margin-top: 200px;
    font-family: 'Ogg';
    font-size: 200px;
}
@media (max-width: 800px) {
    .blog-message{
        font-size: 100px;
    }
}
.scene{

    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 500px;
    height: 400px;
}
.my-projects{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5vw;
    margin-right: 5vw;
    height: 50vh;
    border-bottom: 1px dashed black;
}
.my-projects h1{
    font-family: 'Ogg';
    font-size: 220px;
    font-weight: lighter;
    line-height: 80%;
}
.project-page a{
    color: #665353;
    text-decoration: none;
}
.project-page-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.project-page-items{
    display: flex;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}
.project-page-item{
    padding: 100px;
    width: 400px;  
    transition: -webkit-transform .6s cubic-bezier(.16,1,.3,1);  
    transition: transform .6s cubic-bezier(.16,1,.3,1);  
    transition: transform .6s cubic-bezier(.16,1,.3,1), -webkit-transform .6s cubic-bezier(.16,1,.3,1);

}
.project-page-item:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.project-page-item-number p{
    font-size: 10px;
    font-weight: 500;
    font-family: 'Inter';
    width: 50%;
    text-transform: uppercase;
}
.project-page-item-header{
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
}
.project-page-item-header h1{
    font-size: 15px;
    font-weight: bold;
    width: 50%;
    font-family: 'Inter';
    text-transform: uppercase;
}
.project-page-item-header p{
    font-size: 10px;
    font-weight: 500;
    font-family: 'Inter';
    width: 50%;
    text-transform: uppercase;
}
.project-page-item-image{
    height: 400px;
    width: 100%;
    background-color: gray;
    background-position: 50% 50% ;
    /* filter: grayscale(1); */
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 10px;
}

.project-page-item-footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    padding: 10px 0px;
}
.project-page-item-footer p{
    
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    text-transform: uppercase;
}
.project-page-item-footer img{
    width: 15px;
    margin-right: 5px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
@media (max-width: 900px) {
    .my-projects{
        height: 40vh;
    }
    .my-projects h1{
        font-size: 120px;
    }
    .project-page-item{
        padding: 50px;
    }
}
@media (max-width: 500px) {
    .my-projects{
        height: 30vh;
        margin-top: 100px;
    }
    .my-projects h1{
        font-size: 80px;
    }
    .project-page-item{
        padding: 25px 5px;
    }
}
@media (max-width: 420px){
    .project-page-item{
        width: 300px;
    }
    .project-page-item-image{
        height: 300px;
    }
}
.contact-page-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 150px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;  
    flex-wrap: wrap;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.contact-page-container h1{
    word-spacing: 10px;
    letter-spacing: 80%;
    font-size: 200px;
    font-family: 'Ogg';
    font-weight: lighter;
}
.contact-page-container h1 img{
    width: 100px;
    margin-left: 50px;
    margin-bottom: 100px;
    -webkit-animation: animate-rotate 10s infinite linear;
            animation: animate-rotate 10s infinite linear;
}
.curve{
    position: absolute;
    top: 100px;
    width: 70vw;
    /* right: 30%; */
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    z-index: -1;
}
@-webkit-keyframes animate-rotate {
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes animate-rotate {
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@media (max-width:900px) {
    .contact-page-container h1{
        font-size: 100px;
        line-height: 88%;
    }
}
@media (max-width:500px){
    .curve{
        display: none;
    }
    .contact-page-container h1{
        font-size: 80px;
        line-height: 88%;
    }
    .contact-page-container h1 img{
        width: 50px;
    }
}
