@import '../Assets/font.css';
.contact-page-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 150px;
    height: fit-content;  
    flex-wrap: wrap;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.contact-page-container h1{
    word-spacing: 10px;
    letter-spacing: 80%;
    font-size: 200px;
    font-family: 'Ogg';
    font-weight: lighter;
}
.contact-page-container h1 img{
    width: 100px;
    margin-left: 50px;
    margin-bottom: 100px;
    animation: animate-rotate 10s infinite linear;
}
.curve{
    position: absolute;
    top: 100px;
    width: 70vw;
    /* right: 30%; */
    transform: rotate(180deg);
    z-index: -1;
}
@keyframes animate-rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
@media (max-width:900px) {
    .contact-page-container h1{
        font-size: 100px;
        line-height: 88%;
    }
}
@media (max-width:500px){
    .curve{
        display: none;
    }
    .contact-page-container h1{
        font-size: 80px;
        line-height: 88%;
    }
    .contact-page-container h1 img{
        width: 50px;
    }
}