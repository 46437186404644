@import '../Assets/font.css';
.blog-message{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 90%;
    width: 100%;
    text-decoration: line-through 5px #806868;
    margin-bottom: 150px;
    margin-top: 200px;
    font-family: 'Ogg';
    font-size: 200px;
}
@media (max-width: 800px) {
    .blog-message{
        font-size: 100px;
    }
}