@import '../Assets/font.css';
.scene{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 400px;
}
.my-projects{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5vw;
    margin-right: 5vw;
    height: 50vh;
    border-bottom: 1px dashed black;
}
.my-projects h1{
    font-family: 'Ogg';
    font-size: 220px;
    font-weight: lighter;
    line-height: 80%;
}
.project-page a{
    color: #665353;
    text-decoration: none;
}
.project-page-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}
.project-page-items{
    display: flex;
    justify-content: center;
    height: fit-content;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}
.project-page-item{
    padding: 100px;
    width: 400px;  
    transition: transform .6s cubic-bezier(.16,1,.3,1);

}
.project-page-item:hover{
    transform: scale(1.05);
}
.project-page-item-number p{
    font-size: 10px;
    font-weight: 500;
    font-family: 'Inter';
    width: 50%;
    text-transform: uppercase;
}
.project-page-item-header{
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
}
.project-page-item-header h1{
    font-size: 15px;
    font-weight: bold;
    width: 50%;
    font-family: 'Inter';
    text-transform: uppercase;
}
.project-page-item-header p{
    font-size: 10px;
    font-weight: 500;
    font-family: 'Inter';
    width: 50%;
    text-transform: uppercase;
}
.project-page-item-image{
    height: 400px;
    width: 100%;
    background-color: gray;
    background-position: 50% 50% ;
    /* filter: grayscale(1); */
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 10px;
}

.project-page-item-footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    padding: 10px 0px;
}
.project-page-item-footer p{
    
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    text-transform: uppercase;
}
.project-page-item-footer img{
    width: 15px;
    margin-right: 5px;
    transform: rotate(270deg);
}
@media (max-width: 900px) {
    .my-projects{
        height: 40vh;
    }
    .my-projects h1{
        font-size: 120px;
    }
    .project-page-item{
        padding: 50px;
    }
}
@media (max-width: 500px) {
    .my-projects{
        height: 30vh;
        margin-top: 100px;
    }
    .my-projects h1{
        font-size: 80px;
    }
    .project-page-item{
        padding: 25px 5px;
    }
}
@media (max-width: 420px){
    .project-page-item{
        width: 300px;
    }
    .project-page-item-image{
        height: 300px;
    }
}